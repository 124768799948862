import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import authReducer from './slices/authSlice';
import settingsReducer from './slices/settingsSlice';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { fubApi } from '../api';

const persistConfig = {
  key: 'fub-asta-finance',
  storage,
  whitelist: ['auth', 'settings'],
};

const reducer = combineReducers({
  auth: authReducer,
  settings: settingsReducer,
  [fubApi.reducerPath]: fubApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store: any = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware().concat([fubApi.middleware]),
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
