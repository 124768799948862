import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { loginSuccess } from '../../redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';

const OidcSuccessPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const jwt = Cookies.get('jwt');
    // todo get profile data and also save in authSlice
    if (jwt) {
      navigate('/');
      dispatch(loginSuccess());
    }
  }, []);

  return (
    <div>
      <h1>Success</h1>
    </div>
  );
};

export default OidcSuccessPage;
