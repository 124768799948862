import Table from '../../components/table/Table';
import { useListInventoriesQuery } from '../../api/inventoryApi';
import { formatDateLong } from '../../utils/date';
import AddInventory from './AddInventory';
import { useState, useMemo } from 'react';
import EditInventory from './EditInventory';
import { ButtonGroup, Button } from 'react-bootstrap';

const Inventory = () => {
  const { data: inventoryList, isLoading } = useListInventoriesQuery();

  const [isEditing, setIsEditing] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState(null);

  const calculateBookValue = useMemo(
    () => (price, addedAt, depreciationMonths) => {
      const now = new Date();
      const addedDate = new Date(addedAt);

      let months = (now.getFullYear() - addedDate.getFullYear()) * 12;
      months -= addedDate.getMonth();
      months += now.getMonth();

      if (now.getDate() < addedDate.getDate()) {
        months--;
      }

      let bookValue = price - (months / depreciationMonths) * price;

      if (bookValue > price) {
        bookValue = price;
      } else if (bookValue < 0) {
        bookValue = 0;
      }

      return bookValue.toFixed(2);
    },
    []
  );

  const [selectedQuarters, setSelectedQuarters] = useState([]);

  const toggleQuarter = (quarter) => {
    setSelectedQuarters((prev) =>
      prev.includes(quarter)
        ? prev.filter((q) => q !== quarter)
        : [...prev, quarter]
    );
  };

  const dateRangeFilterFn = useMemo(
    () => (row, columnId, filterValue) => {
      if (filterValue.length === 0) return true; // Show all dates if no quarter is selected
      const rowDate = new Date(row.getValue(columnId));
      const month = rowDate.getMonth();
      const quarter = Math.floor(month / 3) + 1;
      return filterValue.includes(quarter);
    },
    []
  );

  const dateRangeFilter = {
    columnId: 'addedAt',
    filterFn: dateRangeFilterFn,
    filterValues: selectedQuarters,
    filterInputNode: (
      <div className='col-4 d-flex justify-content-start'>
        <ButtonGroup>
          <Button
            variant={selectedQuarters.includes(1) ? 'primary' : 'secondary'}
            onClick={() => toggleQuarter(1)}
          >
            Q1
          </Button>
          <Button
            variant={selectedQuarters.includes(2) ? 'primary' : 'secondary'}
            onClick={() => toggleQuarter(2)}
          >
            Q2
          </Button>
          <Button
            variant={selectedQuarters.includes(3) ? 'primary' : 'secondary'}
            onClick={() => toggleQuarter(3)}
          >
            Q3
          </Button>
          <Button
            variant={selectedQuarters.includes(4) ? 'primary' : 'secondary'}
            onClick={() => toggleQuarter(4)}
          >
            Q4
          </Button>
        </ButtonGroup>
      </div>
    ),
  };

  const columns = [
    {
      header: 'Datum Abgabe',
      accessorKey: 'addedAt',
      cell: (cell: { row: any }) => (
        <>{formatDateLong(cell.row.original.addedAt)}</>
      ),
      defaultSortOrder: 'desc',
      filterFn: dateRangeFilterFn,
    },
    {
      header: 'Bezeichnung',
      accessorKey: 'name',
    },
    {
      header: 'Zubehör',
      accessorKey: 'accessories',
    },
    {
      header: 'Inventarnummer & Ma.-Nr.',
      accessorKey: 'inventoryNumber',
      cell: (cell: { row: any }) => (
        <>
          {`I${cell.row.original.inventoryNumber}-${cell.row.original.externalReference}`}
        </>
      ),
    },
    {
      header: 'Standort',
      accessorKey: 'location',
    },
    {
      header: 'Restbuchwert',
      accessorKey: 'price',
      cell: (cell: { row: any }) => {
        const { price, addedAt, depreciationMonths } = cell.row.original;

        const bookValue = calculateBookValue(
          price,
          addedAt,
          depreciationMonths
        );

        return <>{bookValue} €</>;
      },
    },
  ];

  const handleRowClick = (row) => {
    setSelectedInventory(row);
    setIsEditing(true);
  };

  const closeEditModal = () => {
    setIsEditing(false);
    setSelectedInventory(null);
  };

  return !isLoading && inventoryList ? (
    <>
      <div>
        <h1>Inventarliste</h1>
      </div>
      <div>
        <Table
          data={inventoryList}
          columns={columns}
          onRowClick={handleRowClick}
          export={true}
          noDataText='Keine Inventargegenstände gefunden.'
          disabled={false}
          isSearchable={true}
          headerContent={<AddInventory />}
          headerFilter={dateRangeFilter}
        />
      </div>
      {isEditing && (
        <EditInventory inventory={selectedInventory} onClose={closeEditModal} />
      )}
    </>
  ) : (
    <div>Inventarliste wird geladen</div>
  );
};

export default Inventory;
