import React, { FormEvent, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import InventoryForm from './InventoryForm';
import {
  useDeleteInventoryMutation,
  useUpdateInventoryMutation,
} from '../../api/inventoryApi';

const EditInventory = ({ inventory, onClose }) => {
  const [updateInventory, { isLoading: updateLoading }] =
    useUpdateInventoryMutation();
  const [deleteInventory, { isLoading: deleteLoading }] =
    useDeleteInventoryMutation();
  const [deleting, setDeleting] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (inventory) {
      setFormValues({
        addedAt: inventory.addedAt
          ? new Date(inventory.addedAt).toISOString().slice(0, 10)
          : '',
        externalReference: inventory.external_reference || '',
        name: inventory.name || '',
        price: inventory.price ? inventory.price.toString() : '',
        depreciationMonths: inventory.depreciation_months
          ? inventory.depreciation_months.toString()
          : '',
        accessories: inventory.accessories || '',
      });
    }
  }, [inventory]);

  const setValue = (prop, value) => {
    setFormValues((v) => ({
      ...v,
      [prop]: value,
    }));
  };

  const handleUpdate = (e: FormEvent) => {
    e.preventDefault();
    setErrors(null);

    if (inventory?.id) {
      updateInventory({
        id: inventory.id,
        payload: {
          ...formValues,
          depreciationMonths: parseInt(formValues.depreciationMonths),
          price: parseFloat(formValues.price),
        },
      })
        .unwrap()
        .then(() => {
          toast.success('Inventargegenstand erfolgreich aktualisiert.');
          onClose();
        })
        .catch((error) => setErrors(error?.data?.message));
    }
  };

  const handleDelete = (e: FormEvent) => {
    e.preventDefault();

    if (inventory?.id) {
      deleteInventory(inventory?.id)
        .unwrap()
        .then(() => {
          toast.success('Inventargegenstand erfolgreich gelöscht.');
          onClose();
        });
    }
  };

  return (
    <Modal
      size='lg'
      show={Boolean(inventory)}
      backdrop='static'
      onHide={onClose}
    >
      <Modal.Header>
        <h4>Inventargegenstand {deleting ? 'löschen' : 'bearbeiten'}</h4>
        {!deleting && (
          <button
            className='btn btn-outline-danger'
            onClick={() => setDeleting(true)}
          >
            Gegenstand löschen
          </button>
        )}
      </Modal.Header>
      <Modal.Body>
        {deleting ? (
          <>
            {`Soll der Inventargegenstand mit der Inventarnummer ${inventory?.inventoryNumber || ''}
            ${inventory?.name ? `und der Bezeichnung ${inventory.name}` : ''}
            tatsächlich gelöscht werden? Diese Aktion kann nicht rückgängig gemacht werden.`}
          </>
        ) : (
          formValues && (
            <InventoryForm
              formValues={formValues}
              setValue={setValue}
              onSubmit={handleUpdate}
              errors={errors}
              handleClose={onClose}
            />
          )
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          className='btn btn-secondary'
          onClick={() => (deleting ? setDeleting(false) : onClose())}
        >
          Abbrechen
        </button>
        <button
          type='submit'
          className={`btn btn-${deleting ? 'danger' : 'success'}`}
          onClick={deleting ? handleDelete : handleUpdate}
          disabled={updateLoading || deleteLoading}
        >
          {deleting ? 'Gegenstand löschen' : 'Speichern'}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditInventory;
