import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import Cookies from 'js-cookie';
import { logout } from '../redux/slices/authSlice';

const customFetchBaseQuery = (baseUrl, prepareHeaders) => {
  const baseQuery = fetchBaseQuery({ baseUrl, prepareHeaders });

  return async (endpoint, body, extraOptions) => {
    let response = await baseQuery(endpoint, body, extraOptions);

    if (response.error && response.error.status === 401) {
      // TODO handle 401 properly... re-auth...
      body.dispatch(logout());
      Cookies.remove('jwt');
      window.location.href = '/';
    }
    return response;
  };
};

export const baseQuery = customFetchBaseQuery(
  process.env.REACT_APP_API_URL,
  (headers, { getState, dispatch }) => {
    // @ts-ignore
    const token = Cookies.get('jwt');
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
      return headers;
    }
  }
);
