import Table from '../../components/table/Table';
import { useListAccountsQuery } from '../../api/accountApi';
import AddAccount from './AddAccount';

const Accounts = () => {
  const { data: accountList, isLoading } = useListAccountsQuery();

  const columns = [
    {
      header: 'Kontoname',
      accessorKey: 'name',
    },
    {
      header: 'Konto-ID',
      accessorKey: 'accountNumber',
    },
    {
      header: 'Kontotyp',
      accessorKey: 'isManual',
      cell: (cell: { row: any }) => (
        <>{cell.row.original.isManual ? 'manuell' : 'automatisch'}</>
      ),
    },
  ];

  return !isLoading && accountList ? (
    <>
      <div>
        <Table
          data={accountList}
          columns={columns}
          export={true}
          noDataText='Keine Konten gefunden.'
          disabled={false}
          isSearchable={true}
          headerContent={<AddAccount />}
          headline={'Konten'}
        />
      </div>
    </>
  ) : (
    <div>Konten werden geladen</div>
  );
};

export default Accounts;
