import React, { ReactNode, useEffect, useState } from 'react';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel, // Import global filtering
} from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faFloppyDisk,
  faPencil,
  faPrint,
  faSort,
  faSortDown,
  faSortUp,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { ButtonGroup, Dropdown } from 'react-bootstrap';

const Table = ({
  data,
  columns,
  export: exportData,
  disabled,
  noDataText = 'No data available.',
  onRowClick,
  isSearchable = false,
  inlineEdit = false,
  editButtonPos = columns.length + 1,
  headerContent = null,
  headerFilter = null,
  onUpdate = null,
  onDelete = null,
  headline,
}: {
  data: any[];
  columns: any[];
  export?: boolean;
  disabled?: boolean;
  noDataText?: string;
  onRowClick?: (row: any) => void;
  isSearchable?: boolean;
  inlineEdit?: boolean;
  editButtonPos?: number;
  headerContent?: ReactNode;
  headerFilter?: any;
  onUpdate?: (data: any) => Promise<boolean>;
  onDelete?: (data: any) => void;
  headline?: string;
}) => {
  const [tableData, setTableData] = useState(data);
  const [editedRow, setEditedRow] = useState({ index: null, data: null });
  const [globalFilter, setGlobalFilter] = useState('');

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const initialSorting = columns
    .filter((col) => col.defaultSortOrder)
    .map((col) => ({
      id: col.accessorKey,
      desc: col.defaultSortOrder === 'desc',
    }));

  const [sorting, setSorting] = useState(initialSorting);

  const [customFilter, setCustomFilter] = useState([]);

  useEffect(() => {
    if (headerFilter?.columnId && headerFilter?.filterValues) {
      setCustomFilter([
        {
          id: headerFilter.columnId,
          value: headerFilter.filterValues,
        },
      ]);
    }
  }, [headerFilter]);

  // not so pretty workaround to ensure re-render when data changes
  useEffect(() => {
    setTableData(data);
  }, [data]);

  // Initialize table with global filter row model
  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      globalFilter,
      sorting,
      columnFilters: customFilter,
      pagination: { pageIndex, pageSize },
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    onPaginationChange: (updater) => {
      const updated =
        typeof updater === 'function'
          ? updater({ pageIndex, pageSize })
          : updater;
      setPageIndex(updated.pageIndex);
      setPageSize(updated.pageSize);
    },
    enableMultiSort: true,
  });

  if (inlineEdit && (!onUpdate || !onDelete)) {
    throw new Error(
      'Both onUpdate and onDelete functions are required when inlineEdit is true.'
    );
  }

  // TODO revise saveRowData function (try to avoid additional tableData state)
  const saveRowData = () => {
    if (editedRow.index !== null && onUpdate) {
      onUpdate(editedRow.data)
        .then(() => {
          setTableData((prevData) => {
            const newData = [...prevData];
            newData[editedRow.index] = editedRow.data;
            return newData;
          });
          setEditedRow({ index: null, data: null });
        })
        .catch((error) => {
          console.error('Save failed:', error);
        });
    }
  };

  const cancelEditing = () => {
    setEditedRow({ index: null, data: null });
  };

  const renderEditableCell = (cell) => {
    const { column, row, getContext } = cell;
    const isEditing = editedRow.index === row.index;

    if (column.columnDef.readOnly || !inlineEdit || !isEditing) {
      return flexRender(cell.column.columnDef.cell, getContext());
    }

    return (
      <input
        type={cell.column.columnDef.inputType === 'number' ? 'number' : 'text'}
        autoFocus
        value={editedRow.data?.[column.id] || ''}
        onChange={(e) => {
          setEditedRow((prevRow) => ({
            ...prevRow,
            data: { ...prevRow.data, [column.id]: e.target.value },
          }));
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') saveRowData();
          if (e.key === 'Escape') cancelEditing();
        }}
      />
    );
  };

  return (
    <div>
      {isSearchable && data?.length > 0 && (
        <div className='row mb-3'>
          <div className='col-3'>
            <input
              type='text'
              className='form-control'
              placeholder='Suchen...'
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
          </div>
        </div>
      )}
      {headerFilter?.filterInputNode && (
        <div className='row mb-3'>{headerFilter.filterInputNode}</div>
      )}

      {headerContent && (
        <>
          {headline && <h3>{headline}</h3>}
          <div className='row mb-2'>
            <div className='col-12 bg-gray-2 py-2 d-flex'>
              <div className='d-flex justify-content-start'>
                {headerContent}
              </div>
              {exportData && !(!data || data.length === 0) && (
                <>
                  <ButtonGroup
                    as={Dropdown}
                    className='d-inline-block me-1 my-1 ms-auto'
                  >
                    <Dropdown.Toggle variant='secondary' className='arrow-none'>
                      {' '}
                      <FontAwesomeIcon icon={faDownload} className='me-2' />
                      <FontAwesomeIcon icon={faPrint} className='me-2' />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <span className='dropdown-header'>Exportieren als:</span>
                      <Dropdown.Item>PDF</Dropdown.Item>
                      <Dropdown.Item>XLSX</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item>Ausdrucken</Dropdown.Item>
                    </Dropdown.Menu>
                  </ButtonGroup>
                </>
              )}
            </div>
          </div>
        </>
      )}

      {!data || data.length === 0 ? (
        <div>{noDataText}</div>
      ) : (
        <>
          <table className='table'>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, index) => (
                    <>
                      {index === editButtonPos - 1 && inlineEdit && (
                        <th>&nbsp;</th>
                      )}
                      <th
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        <span>
                          <FontAwesomeIcon
                            icon={
                              header.column.getIsSorted() === 'desc'
                                ? faSortDown
                                : header.column.getIsSorted() === 'asc'
                                  ? faSortUp
                                  : faSort
                            }
                            className='ms-1'
                          />
                        </span>
                      </th>
                    </>
                  ))}
                  {editButtonPos > columns.length && inlineEdit && (
                    <th>&nbsp;</th>
                  )}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  onClick={() => {
                    if (
                      !disabled &&
                      onRowClick &&
                      editedRow.index !== row.index
                    ) {
                      onRowClick(row.original);
                      console.log('row clicked! ', row.original);
                    }
                  }}
                  style={{
                    cursor: !disabled && onRowClick ? 'pointer' : 'default',
                  }}
                >
                  {row.getVisibleCells().map((cell, index) => (
                    <>
                      {index === editButtonPos - 1 && inlineEdit && (
                        <td>
                          {editedRow.index === row.index ? (
                            <>
                              <button
                                className='btn btn-sm btn-success me-1'
                                onClick={saveRowData}
                              >
                                <FontAwesomeIcon
                                  icon={faFloppyDisk}
                                  className='me-1'
                                />{' '}
                                Speichern
                              </button>
                              <button
                                className='btn btn-sm btn-warning'
                                onClick={cancelEditing}
                              >
                                Abbrechen
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEditedRow({
                                    index: row.index,
                                    data: {
                                      ...(row.original as Record<string, any>),
                                    },
                                  });
                                }}
                                className='btn btn-sm btn-outline-secondary me-1'
                              >
                                <FontAwesomeIcon icon={faPencil} />
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (onDelete) onDelete(row.original);
                                }}
                                className='btn btn-sm btn-outline-danger'
                              >
                                <FontAwesomeIcon icon={faTrashCan} />
                              </button>
                            </>
                          )}
                        </td>
                      )}
                      <td key={cell.id}>{renderEditableCell(cell)}</td>
                    </>
                  ))}
                  {editButtonPos > columns.length && inlineEdit && (
                    <td>
                      {editedRow.index === row.index ? (
                        <>
                          <button
                            className='btn btn-sm btn-success me-1'
                            onClick={saveRowData}
                          >
                            <FontAwesomeIcon
                              icon={faFloppyDisk}
                              className='me-1'
                            />{' '}
                            Speichern
                          </button>
                          <button
                            className='btn btn-sm btn-warning'
                            onClick={cancelEditing}
                          >
                            Abbrechen
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditedRow({
                                index: row.index,
                                data: {
                                  ...(row.original as Record<string, any>),
                                },
                              });
                            }}
                            className='btn btn-sm btn-outline-secondary me-1'
                          >
                            <FontAwesomeIcon icon={faPencil} />
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              if (onDelete) onDelete(row.original);
                            }}
                            className='btn btn-sm btn-outline-danger'
                          >
                            <FontAwesomeIcon icon={faTrashCan} />
                          </button>
                        </>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <div className='mt-3 d-flex justify-content-center align-items-center'>
            <button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              className='btn btn-sm btn-secondary me-2'
            >
              Vorherige Seite
            </button>
            <span>
              Seite {table.getState().pagination.pageIndex + 1} von{' '}
              {table.getPageCount()}
            </span>
            <button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              className='btn btn-sm btn-secondary mx-2'
            >
              Nächste Seite
            </button>
            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => table.setPageSize(Number(e.target.value))}
              className='form-select form-select-sm w-auto'
            >
              {[25, 50, 100, 150].map((size) => (
                <option key={size} value={size}>
                  {size} Einträge
                </option>
              ))}
            </select>
          </div>
        </>
      )}
    </div>
  );
};

export default Table;
