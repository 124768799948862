import React, { FormEvent, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useCreateAccountMutation } from '../../api/accountApi';
import AccountForm from './AccountForm';

const AddInventory = () => {
  const [createAccount, { isLoading }] = useCreateAccountMutation();
  const [errors, setErrors] = useState(null);
  const [open, setOpen] = useState(false);

  const defaultFormValues = {
    isManual: false,
    name: '',
    startBalance: '',
    accountComment: '',
  };

  const [formValues, setFormValues] = useState(defaultFormValues);

  const setValue = (prop, value) => {
    setFormValues((v) => ({
      ...v,
      [prop]: value,
    }));
  };

  const handleOpen = () => {
    setErrors(null);
    setFormValues(defaultFormValues);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setErrors(null);
    createAccount({
      ...formValues,
      startBalance: parseFloat(formValues.startBalance),
    })
      .unwrap()
      .then(() => {
        toast.success('Konto erfolgreich angelegt.');
        handleClose();
      })
      .catch((error) => setErrors(error?.data?.message));
  };

  return (
    <>
      <button className='btn btn-lg px-5 btn-primary' onClick={handleOpen}>
        Neues Konto erstellen
      </button>
      <Modal size='lg' show={open} backdrop='static' onHide={handleClose}>
        <Modal.Header>
          <h4>Konto erstellen</h4>
        </Modal.Header>
        <Modal.Body>
          <AccountForm
            formValues={formValues}
            setValue={setValue}
            errors={errors}
            onSubmit={handleSubmit}
            handleClose={handleClose}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={handleClose}
          >
            Abbrechen
          </button>
          <button
            type='submit'
            className='btn btn-success'
            onClick={handleSubmit}
          >
            Speichern
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddInventory;
