const formatDateLong = (dateString: string): string => {
  if (!dateString || !dateIsValid(dateString)) return '';
  return new Intl.DateTimeFormat('de', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(new Date(dateString));
};

function dateIsValid(date) {
  return !Number.isNaN(new Date(date).getTime());
}

export { formatDateLong, dateIsValid };
