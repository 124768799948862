const LoginPage = () => {
  return (
    <div className='justify-content-center align-items-center row mt-5'>
      <div className='col-lg-4 col-md-6 col-12'>
        <div className='card shadow'>
          <div className='card-body text-center'>
            <h3 className='tx-color-02'>Login</h3>
            <p className='tx-color-03'>
              Bitte authentifiziere dich über Single-Sign-On (SSO).
            </p>
            <a
              href={`${process.env.REACT_APP_API_URL}/api/auth/login`}
              className='btn btn-primary'
            >
              Login über SSO
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
