import React from 'react';

const InventoryForm = ({
  formValues,
  setValue,
  onSubmit,
  errors,
  handleClose,
}) => {
  return (
    <form onSubmit={onSubmit}>
      {errors && (
        <div className='alert alert-danger'>
          <b>Es gab Fehler beim Speichern:</b>
          <ul>
            {errors.map((err, index) => (
              <li key={index}>{err}</li>
            ))}
          </ul>
        </div>
      )}
      <div className='row g-3 mb-3'>
        <div className='col-3'>
          <label htmlFor='addedAt' className='form-label'>
            Datum Abgabe
          </label>
          <input
            type='date'
            id='addedAt'
            className='form-control'
            value={formValues.addedAt ?? ''}
            onChange={(e) => setValue('addedAt', e.target.value)}
          />
        </div>
        <div className='col-6 align-self-end'>
          <label htmlFor='name' className='form-label'>
            Geräte / Markenbezeichnung
          </label>
          <input
            type='text'
            id='name'
            className='form-control'
            placeholder='Geräte / Markenbezeichnung'
            value={formValues.name ?? ''}
            onChange={(e) => setValue('name', e.target.value)}
          />
        </div>
        <div className='col-3 align-self-end'>
          <label htmlFor='price' className='form-label'>
            Preis
          </label>
          <div className='input-group'>
            <input
              type='number'
              id='price'
              className='form-control'
              placeholder='Preis'
              min={0}
              step={0.01}
              value={formValues.price ?? ''}
              onChange={(e) => setValue('price', e.target.value)}
            />
            <span className='input-group-text'>€</span>
          </div>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-3'>
          <label htmlFor='depreciationMonths' className='form-label'>
            Laufzeit (Monate)
          </label>
          <input
            type='number'
            step={1}
            min={1}
            placeholder='Laufzeit (Monate)'
            id='depreciationMonths'
            className='form-control'
            value={formValues.depreciationMonths ?? ''}
            onChange={(e) => setValue('depreciationMonths', e.target.value)}
          />
        </div>
        <div className='col-6'>
          <label htmlFor='externalReference' className='form-label'>
            MA-Nr. / Referenz
          </label>
          <input
            type='text'
            id='externalReference'
            className='form-control'
            value={formValues.externalReference ?? ''}
            placeholder='MA-Nr. / Referenz'
            onChange={(e) => setValue('externalReference', e.target.value)}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-6'>
          <label htmlFor='accessories' className='form-label'>
            Zubehör
          </label>
          <input
            type='text'
            id='accessories'
            className='form-control'
            placeholder='Zubehör'
            value={formValues.accessories ?? ''}
            onChange={(e) => setValue('accessories', e.target.value)}
          />
        </div>
      </div>
    </form>
  );
};

export default InventoryForm;
