import React, { FormEvent, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useCreateInventoryMutation } from '../../api/inventoryApi';
import { useDispatch } from 'react-redux';
import InventoryForm from './InventoryForm';
import { fubApi } from '../../api';

const AddInventory = () => {
  const [createInventory, { isLoading }] = useCreateInventoryMutation();
  const [errors, setErrors] = useState(null);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const defaultFormValues = {
    addedAt: '',
    externalReference: '',
    name: '',
    price: '',
    depreciationMonths: '',
    accessories: '',
    location: '',
  };

  const [formValues, setFormValues] = useState(defaultFormValues);

  const setValue = (prop, value) => {
    setFormValues((v) => ({
      ...v,
      [prop]: value,
    }));
  };

  const handleOpen = () => {
    setErrors(null);
    setFormValues(defaultFormValues);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setErrors(null);
    createInventory({
      ...formValues,
      depreciationMonths: parseInt(formValues.depreciationMonths),
      price: parseFloat(formValues.price),
    })
      .unwrap()
      .then(() => {
        toast.success('Inventargegenstand erfolgreich angelegt.');
        dispatch(fubApi.util.invalidateTags(['InventoryList']));
        handleClose();
      })
      .catch((error) => setErrors(error?.data?.message));
  };

  return (
    <>
      <button className='btn btn-lg px-5 btn-primary' onClick={handleOpen}>
        Neuer Inventargegenstand
      </button>
      <Modal size='lg' show={open} backdrop='static' onHide={handleClose}>
        <Modal.Header>
          <h4>Inventargegenstand hinzufügen</h4>
        </Modal.Header>
        <Modal.Body>
          <InventoryForm
            formValues={formValues}
            setValue={setValue}
            errors={errors}
            onSubmit={handleSubmit}
            handleClose={handleClose}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={handleClose}
          >
            Abbrechen
          </button>
          <button
            type='submit'
            className='btn btn-success'
            onClick={handleSubmit}
          >
            Speichern
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddInventory;
