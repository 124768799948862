import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import { logout } from '../../redux/slices/authSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FiscalYearBox from './FiscalYearBox';
import { Container, Nav, Navbar } from 'react-bootstrap';

const TopNav = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { authenticated } = useSelector((state: RootState) => state.auth);

  const handleLogout = () => {
    Cookies.remove('jwt');
    dispatch(logout());
    navigate('/');
  };

  return (
    <Navbar expand='lg' className='navbar-light bg-light bd px-3'>
      <Container fluid>
        <Navbar.Brand href='/' className='tx-bold tx-spacing--2 order-1'>
          finref
        </Navbar.Brand>
        {authenticated ? (
          <>
            <Navbar.Toggle aria-controls='navbarSupportedContent' />

            <Navbar.Collapse id='navbarSupportedContent' className='order-2'>
              <Nav className='me-auto'>
                <Nav.Link href='#'>Protokolle</Nav.Link>
                <Nav.Link href='#'>Anträge</Nav.Link>
                <Nav.Link href='/accounts'>Konten</Nav.Link>
                <Nav.Link href='#'>Buchungen</Nav.Link>
                <Nav.Link href='/inventory'>Inventar</Nav.Link>
                <Nav.Link href='/tables'>Tabellen</Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link href='#'>
                  <FontAwesomeIcon icon={faUserGroup} className='me-1' />
                  Verwaltung
                </Nav.Link>
                <FiscalYearBox />
                <Nav.Item>
                  <button
                    className='btn btn-outline-secondary ms-1'
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </>
        ) : null}
      </Container>
    </Navbar>
  );
};

export default TopNav;
