import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { setFiscalYear } from '../../redux/slices/authSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Modal, NavItem } from 'react-bootstrap';

const FiscalYearBox = () => {
  const { fiscalYear } = useSelector((state: RootState) => state.auth);
  const dispatch: AppDispatch = useDispatch();
  const [selectedYear, setSelectedYear] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSelectedYear(fiscalYear);
  }, [fiscalYear]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setFiscalYear({ fiscalYear: selectedYear }));
    setOpen(false);
  };

  return (
    <>
      <NavItem>
        <a
          className='btn-link nav-link'
          role='button'
          onClick={() => setOpen(true)}
        >
          <span className='fy-text'>{fiscalYear} &nbsp;</span>
          <span className='fy-text-hover'>ändern &nbsp; </span>
          <FontAwesomeIcon icon={faCalendar} className='fy-icon' />
          <FontAwesomeIcon icon={faPenToSquare} className='fy-icon-hover' />
        </a>
      </NavItem>
      <Modal show={open} backdrop='static' keyboard={false}>
        <Modal.Header>
          <Modal.Title>Haushaltsjahr ändern</Modal.Title>
        </Modal.Header>
        <form onSubmit={(event) => handleSubmit(event)}>
          <Modal.Body>
            <div className='form-group'>
              <label htmlFor='year' className='form-label'>
                Haushaltsjahr festlegen
              </label>
              <select
                className='form-control'
                value={selectedYear ?? 2024}
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                <option value={2024}>2024</option>
                <option value={2023}>2023</option>
                <option value={2022}>2022</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={() => setOpen(false)}
            >
              Abbrechen
            </button>
            <button type='submit' className='btn btn-primary'>
              Speichern
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default FiscalYearBox;
