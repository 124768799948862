import { fubApi } from './index';

const inventoryApi = fubApi.injectEndpoints({
  endpoints: (builder) => ({
    listInventories: builder.query<any, void>({
      query: () => `api/v1/inventory`,
      providesTags: [{ type: 'InventoryList', id: 'LIST' }],
    }),
    createInventory: builder.mutation<any, any>({
      query: (payload) => ({
        url: `api/v1/inventory`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'InventoryList', id: 'LIST' }],
    }),
    updateInventory: builder.mutation<any, any>({
      query: ({ id, payload }) => ({
        url: `api/v1/inventory/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'InventoryList', id: 'LIST' }],
    }),
    deleteInventory: builder.mutation<any, any>({
      query: (id) => ({
        url: `api/v1/inventory/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'InventoryList', id: 'LIST' }],
    }),
  }),
});

export const {
  useListInventoriesQuery,
  useCreateInventoryMutation,
  useUpdateInventoryMutation,
  useDeleteInventoryMutation,
} = inventoryApi;
