import { Outlet } from 'react-router-dom';
import TopNav from './topNav';
import packageJson from '../../package.json';
import React from 'react';

const AppLayout = () => {
  return (
    <div className='d-flex flex-column min-vh-100'>
      <header>
        <TopNav />
      </header>
      <main className='flex-grow-1 mt-3'>
        <div className='container'>
          <Outlet />
        </div>
      </main>
      <footer className='footer mt-2'>
        <div className='footer-content'>
          finref {packageJson.version} - <a href={'#'}>Datenschutz</a> -{' '}
          <a href={'#'}>Legals</a>
        </div>
      </footer>
    </div>
  );
};

export default AppLayout;
