import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import AppLayout from './layout';
import Login from './pages/login';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import OidcSuccessPage from './pages/oidcSuccess';
import DashboardPage from './pages/dashboard';
import TablesPage from './pages/tables';
import Inventory from './pages/inventory';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Accounts from './pages/accounts';

function App() {
  const { authenticated } = useSelector((state: RootState) => state.auth);
  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<AppLayout />}>
          {authenticated ? (
            <>
              <Route index element={<DashboardPage />} />
              <Route path='/tables' element={<TablesPage />} />
              <Route path='/inventory' element={<Inventory />} />
              <Route path='/accounts' element={<Accounts />} />
            </>
          ) : (
            <>
              <Route index element={<Login />} />
              <Route path='/oidc-success' element={<OidcSuccessPage />} />
            </>
          )}
        </Route>
      </Routes>
      <ToastContainer
        autoClose={3000}
        position={'top-right'}
        draggable={false}
        closeOnClick={true}
      />
    </div>
  );
}

// <div className='App'>
//       <header className="navbar navbar-header navbar-header-fixed">...</header>
//
//       <div className="content content-fixed bd-b">
//         <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
//             <h4>Test</h4>
//         </div>
//       </div>
//
//       <footer className="footer">Footer here</footer>
//     </div>

export default App;
