import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  authenticated: false,
  fiscalYear: 2024,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess(state) {
      state.authenticated = true;
    },
    setFiscalYear(state, action) {
      state.fiscalYear = action.payload.fiscalYear;
    },
    logout(state) {
      Object.assign(state, initialState);
    },
  },
});

export const { loginSuccess, setFiscalYear, logout } = authSlice.actions;

export default authSlice.reducer;
