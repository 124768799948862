import { fubApi } from './index';

const accountApi = fubApi.injectEndpoints({
  endpoints: (builder) => ({
    listAccounts: builder.query<any, void>({
      query: () => `api/v1/account`,
      providesTags: [{ type: 'AccountList', id: 'LIST' }],
    }),
    createAccount: builder.mutation<any, any>({
      query: (payload) => ({
        url: `api/v1/account`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'AccountList', id: 'LIST' }],
    }),
    updateAccount: builder.mutation<any, any>({
      query: ({ id, payload }) => ({
        url: `api/v1/account/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'AccountList', id: 'LIST' }],
    }),
  }),
});

export const {
  useCreateAccountMutation,
  useUpdateAccountMutation,
  useListAccountsQuery,
} = accountApi;
