import Table from '../../components/table/Table';

const TablesPage = () => {
  const data = [
    { id: 1, name: 'John Doe', age: 28, profession: 'Developer' },
    { id: 2, name: 'Jane Smith', age: 34, profession: 'Designer' },
    { id: 3, name: 'Sam Johnson', age: 45, profession: 'Manager' },
  ];

  const columns = [
    {
      header: 'ID',
      accessorKey: 'id',
      readOnly: true,
    },
    {
      header: 'Name',
      accessorKey: 'name',
    },
    {
      header: 'Age',
      accessorKey: 'age',
      inputType: 'number',
    },
    {
      header: 'Profession',
      accessorKey: 'profession',
    },
  ];

  const handleUpdate = async (updatedRow) => {
    try {
      console.log(updatedRow);
      // Hier den API call zum updaten machen und wenn das erfolgreich war, true zurückgeben
      return true;
    } catch (error) {
      console.error('Error updating row:', error);
      throw error;
    }
  };

  const handleRowClick = (row) => {
    alert(`Row clicked: ${JSON.stringify(row)}`);
  };

  const headerButtons = (
    <button className='btn btn-lg btn-primary'>Testing</button>
  );

  return (
    <>
      <div>
        <h1>Tabellen Test</h1>
      </div>
      <div>
        <Table
          data={data}
          columns={columns}
          onRowClick={handleRowClick}
          export={true}
          noDataText='No records found'
          disabled={false}
          inlineEdit={true}
          isSearchable={true}
          headerContent={headerButtons}
          onUpdate={handleUpdate}
          onDelete={handleUpdate}
        />
      </div>
    </>
  );
};

export default TablesPage;
